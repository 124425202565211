import React, {PropsWithChildren} from "react";
import {Button, Icon, Theme} from "@mui/material";
import DialogButton from "../DialogButton";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    sectionHeaderContainer: {
        display: "flex",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        zIndex: 100
    },
    sectionHeaderButtonContainer: {
        display: "inline-flex",
        marginLeft: "auto",
        marginRight: "0"
    },
    sectionHeaderButton: {
        minWidth: "unset",
        padding: "0 5px",
        marginLeft: "3px",
        color: "rgb(107,133,165)",
        backgroundColor: "rgba(0,0,0,0.06)",
        zIndex: 1,
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.10)"
        }
    },
    modeLabel: {
        color: "darkgrey",
        padding: "1px 0 0 5px",
        fontSize: "9px",
        lineHeight: "11px"
    },
    positionLabel: {
        backgroundColor: "darkgrey",
        color: "#ffffff",
        position: "absolute",
        top: "-1px",
        left: "-15px",
        width: "14px",
        height: "14px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
        lineHeight: "14px",
        [theme.breakpoints.down(950)]: {
            left: "0"
        }
    },
    typeLabelContainer: {
        position: "absolute",
        top: "-1px",
        left: "0",
        width: "100%",
        height: "14px",
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
        lineHeight: "14px"
    },
    typeLabel: {
        backgroundColor: "darkgrey",
        color: "#ffffff",
        width: "250px",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        borderBottomLeftRadius: "15px",
        borderBottomRightRadius: "15px"
    },
    labelEditMode: {
        backgroundColor: "rgb(107,133,165)"
    },
    invisible: {
        display: "none"
    }
}));

interface EditableSectionHeaderProps extends PropsWithChildren<any> {
    position: string | number;
    label: string;
    hovered: boolean;
    editMode: boolean;
    userEditMode: boolean;

    onSaveSection(): void;

    onRemoveSection(): void;

    onToggleEditMode(): void;
}

export default function EditableSectionHeader(props: EditableSectionHeaderProps) {
    const {position, label, hovered, editMode, userEditMode, onSaveSection, onRemoveSection, onToggleEditMode} = props;

    const classes = useStyles();

    return (
        <div className={classes.sectionHeaderContainer}>
            <div className={`${classes.positionLabel}${editMode ? " " + classes.labelEditMode : ""}`}>{position}</div>
            <div className={classes.typeLabelContainer}>
                <div className={`${classes.typeLabel}${editMode ? " " + classes.labelEditMode : ""}`}>{label}</div>
            </div>
            {editMode && (
                <div className={classes.modeLabel}>Bearbeitungsmodus</div>
            )}
            <div className={`${classes.sectionHeaderButtonContainer}${!hovered ? " " + classes.invisible : ""}`}>
                {(editMode && !userEditMode) && (
                    <Button className={classes.sectionHeaderButton}
                            onClick={onSaveSection}>
                        <Icon>done</Icon>
                    </Button>
                )}
                {(editMode && userEditMode) && (
                    <DialogButton label={<Icon>done</Icon>}
                                  title="Bereich wirklich verändern?"
                                  actionLabel="Speichern"
                                  onActionClick={onSaveSection}
                                  style={{ backgroundColor: "unset", borderRadius: "unset"}}
                                  buttonStyle={{ border: "none" }}
                                  buttonClassName={classes.sectionHeaderButton}/>
                )}
                {(!editMode && !userEditMode) && (
                    <DialogButton label={<Icon>delete_outline</Icon>}
                                  title="Bereich wirklich löschen?"
                                  actionLabel="Löschen"
                                  onActionClick={onRemoveSection}
                                  style={{ backgroundColor: "unset", borderRadius: "unset"}}
                                  buttonStyle={{ border: "none" }}
                                  buttonClassName={classes.sectionHeaderButton}/>
                )}
                <Button className={classes.sectionHeaderButton} onClick={onToggleEditMode}>
                    <Icon>{!editMode ? 'edit' : 'clear'}</Icon>
                </Button>
            </div>
        </div>
    );
}
