import React, {PropsWithChildren} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {format, isBefore} from "date-fns";
import deLocale from "date-fns/locale/de";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    dayContainer: {
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        padding: "8px 0",
        width: "38px",
        height: "65px",
        margin: "0 10px",
        borderRadius: "10px",
        backgroundColor: "transparent",
        color: "#363E4C",
        textAlign: "center",
        cursor: "pointer",
        "&.selected": {
            backgroundColor: "#023553",
            color: "#FFFFFF"
        },
        "&.dayInPast": {
            color: "#D0D0D0"
        },
        [theme.breakpoints.down(950)]: {
            width: "37px",
            margin: "3px"
        },
        [theme.breakpoints.down(415)]: {
            width: "36px",
            margin: "0"
        }
    },
    day: {
        width: "100%"
    },
    dayName: {
        fontSize: "14px",
        lineHeight: "17px",
        fontWeight: 500,
        marginLeft: "auto",
        marginRight: "auto",
    },
    dayValue: {
        fontSize: "22px",
        lineHeight: "33px",
        fontWeight: 600,
        [theme.breakpoints.down(415)]: {
            fontSize: "20px",
            fontWeight: 500,
        }
    },
    dayStatus: {
        width: "7px",
        height: "7px",
        borderRadius: "7px",
        margin: "auto auto 0",
    },
    dayStatusSomeSlots: {
        backgroundColor: "#F8DA8F",
    },
    dayStatusNoSlots: {
        backgroundColor: "#FE6B6B",
    }
}));

interface DayOfWeekProps extends PropsWithChildren<any> {
    dayOfWeek: Date;
    indexOfWeek: number;
    currentSlotCount: number;

    onDaySelected(selectedIndex: number): void;

    onDayIsSelected(dayOfWeek: Date): boolean;
}

export default function DayOfWeek(props: DayOfWeekProps) {

    const {dayOfWeek, indexOfWeek, currentSlotCount, onDayIsSelected, onDaySelected} = props;

    const classes = useStyles();
    const now: Date = new Date(new Date().setHours(0,0,0, 0));

    const handleSelectDate = (): void => {
        onDaySelected(indexOfWeek);
    }

    const handleDayOfWeekIsInPast = (): boolean => {
        return isBefore(dayOfWeek, now);
    }

    const handleDayContainerClassNames = (): string => {
        return (onDayIsSelected(dayOfWeek) ? " selected" : "")
            + (handleDayOfWeekIsInPast() ? " dayInPast" : "")
    }

    const handleDayStatusClassNames = (): string => {
        return handleDayOfWeekIsInPast() ? "" : currentSlotCount <= 0 ? " " + classes.dayStatusNoSlots :
            (currentSlotCount < 3 ? " " + classes.dayStatusSomeSlots : "");
    }

    return (
        <div className={`${classes.dayContainer}${handleDayContainerClassNames()}`}
             onClick={!handleDayOfWeekIsInPast() ? handleSelectDate : undefined}>
            <div className={`${classes.day} ${classes.dayName}`}>
                {format(dayOfWeek, "EEEEEE", {locale: deLocale, weekStartsOn: 1})}
            </div>
            <div className={`${classes.day} ${classes.dayValue}`}>
                {dayOfWeek.getDate()}
            </div>
            <div className={`${classes.day} ${classes.dayStatus}${handleDayStatusClassNames()}`}/>
        </div>
    );
}
