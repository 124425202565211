import React, {CSSProperties, PropsWithChildren, useContext, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import ContentServiceApi from "../../api/ContentServiceApi";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import EditableSection from "./EditableSection";
import ISection from "../../model/site/ISection";
import ISite, {AccessType} from "../../model/site/ISite";
import {ApiError} from "../../model/Errors";
import AddSite from "./AddSite";
import AddSection from "./AddSection";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

const useStyles = makeStyles(() => createStyles({
    editableSiteContainer: {
        //position: "relative",
        //margin: "0 auto",
        //maxWidth: "1280px"
    },
    editableSiteButtonContainer: {
        height: "28px",
        width: "100%",
        display: "inline-flex",
        flexDirection: "column",
        position: "absolute",
        boxSizing: "border-box"
    },
    button: {
        marginLeft: "auto",
        minWidth: "unset",
        padding: "2px"
    },
}));

interface EditableSiteProps extends PropsWithChildren<any> {
    siteType: string;
    editableSiteStyle?: CSSProperties;
    buttonClassName?: string;
    allowAddSection?: boolean;
    allowAddSite?: boolean;
    onUserAllowedToEdit?: () => boolean;
}

export default function EditableSite(props: EditableSiteProps) {
    const {siteType, editableSiteStyle, allowAddSection, allowAddSite} = props;
    const {editMode, getToken} = useContext(AuthServiceContext);

    const addSectionAllowed = typeof allowAddSection === "boolean" ? allowAddSection : true;
    const addSiteAllowed = typeof allowAddSite === "boolean" ? allowAddSite : true;

    const [site, setSite] = useState<ISite>();
    const [sections, setSections] = useState<ISection[]>([]);
    const [userEditMode, setUserEditMode] = useState<boolean>(false);

    const classes = useStyles();

    useEffect(() => {
        console.info("load site of type " + siteType);
        ContentServiceApi.getSite(siteType, getToken()).then(response => {
            setSite(response);
        }).catch((error: ApiError) => {
            if (error.status !== 404) {
                console.error("unexpected error: " + error.message);
            }
        });
    }, [siteType, getToken]);

    useEffect(() => {
        if (site) {
            // sort to make sure ordered by position
            const sections = site.sections.sort((a: ISection, b: ISection) => {
                return a.position - b.position;
            });
            setSections(sections);
        }
    }, [site]);

    const updateSection = (section: ISection) => {
        ContentServiceApi.updateSection(section, getToken()).catch(() => {
            console.info(`error during update section: ${section.type} of site: ${site && site.type}`);
        });
    }

    const handleSaveSection = (section: ISection) => {
        updateSection(section);
    }

    const handleRemoveSection = (section: ISection) => {
        const filteredSections = sections.filter(function (sec) {
            return sec.type !== section.type
        });
        const indexDeletedItem = sections.findIndex(sec => sec.type === section.type);
        const updatingSections = JSON.parse(JSON.stringify(filteredSections)).splice(indexDeletedItem);
        const newSections: ISection[] = [...filteredSections.splice(0, indexDeletedItem)];
        updatingSections.forEach((sec: ISection, index: number) => {
            sec.position = indexDeletedItem + index + 1;
            newSections.push(sec);
            updateSection(sec);
        });
        ContentServiceApi.deleteSection(section, getToken()).then(() => {
            setSections(newSections);
        }).catch((error) => {
            console.info(`error during delete section: ${section.type} of site: ${site && site.type}`, error);
        });

    }

    const handleAddSection = (sectionName: string) => {
        if (site) {
            const fullSectionName = site.type + "_" + sectionName;
            const position = sections.length + 1;
            const section = {type: fullSectionName, position: position, content: "<p/>"}
            ContentServiceApi.createSection(site.type, section, getToken()).then(() => {
                const updatedSection = [...sections, section];
                setSections(updatedSection);
            }).catch((error) => {
                console.info(`error during create section: ${section.type} of site: ${site && site.type}`, error);
            });
        }
    }

    const handleAddSite = () => {
        if (addSiteAllowed) {
            ContentServiceApi.createSite({
                type: siteType,
                accessType: AccessType.NONE,
                sections: []
            }, getToken())
                .then(response => {
                    setSite(response);
                }).catch((error) => {
                console.info(`error during create site: ${siteType}`, error);
            });
        }
    }

    const handleAllowedToEdit = () => {
        return !!props.onUserAllowedToEdit ? props.onUserAllowedToEdit() : false;
    }

    return (
        <>
            {site && (<div className={`editableSiteContainer ${classes.editableSiteContainer}`}
                           style={editableSiteStyle}>
                <div className={`editableSiteButtonContainer ${classes.editableSiteButtonContainer}`}>
                    {(!editMode && handleAllowedToEdit()) && (
                        <Button className={classes.button}
                                onClick={() => setUserEditMode(!userEditMode)}>
                            <Icon>{!userEditMode ? "edit" : "clear"}</Icon>
                        </Button>)}
                </div>
                <div className="editableSiteSectionContainer">
                    {sections.map((section: ISection, index: number) => {
                        let sectionExtensionChild: any[] = [];
                        React.Children.forEach(props.children, function (child) {
                            if (child.props.position === section.position) {
                                sectionExtensionChild.push(child);
                            }
                        });
                        return (
                            <React.Fragment key={index}>
                                <EditableSection key={section.type}
                                                 section={section}
                                                 editMode={editMode}
                                                 userEditMode={userEditMode}
                                                 onSaveSection={handleSaveSection}
                                                 onRemoveSection={handleRemoveSection}>
                                    {sectionExtensionChild && (sectionExtensionChild)}
                                </EditableSection>
                            </React.Fragment>
                        )
                    })}
                </div>
                {(editMode && addSectionAllowed) && (<AddSection onAddSection={handleAddSection}/>)}
            </div>)}
            {(!site && editMode && addSiteAllowed) && (<AddSite onAddSite={handleAddSite}/>)}
        </>
    );
}
