import React, {CSSProperties, PropsWithChildren, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import Editor from "../Editor";
import EditableSectionHeader from "./EditableSectionHeader";
import ISection from "../../model/site/ISection";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    sectionContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        maxWidth: "1280px",
        boxSizing: "border-box",
        padding: "5px 28px",
        [theme.breakpoints.down(950)]: {
            padding: "5px 10px"
        }
    },
    sectionEditorContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        minHeight: "49px",
        boxSizing: "border-box",
        border: "1px dashed transparent",
        padding: "13px 15px 0 15px",
        [theme.breakpoints.down(950)]: {
            padding: "13px 10px 0"
        }
    },
    sectionContainerEditable: {
        borderColor: "lightgrey"
    },
    sectionContainerEnabled: {
        borderColor: "rgb(107,133,165)"
    },
    beforeSectionContainer: {
        display: "flex",
        flexDirection: "column"
    },
    inSectionContainer: {
        display: "flex",
        flexDirection: "column"
    },
    behindSectionContainer: {
        display: "flex",
        flexDirection: "column"
    },
}));

interface SectionExtensionProps extends PropsWithChildren<any> {
    position: number;
    style?: CSSProperties;
}

export function BeforeSectionExtension(props: SectionExtensionProps) {
    const classes = useStyles();
    return (
        <div style={props.style} className={classes.beforeSectionContainer}>{props.children}</div>
    );
}

export function InSectionExtension(props: SectionExtensionProps) {
    const classes = useStyles();
    return (
        <div style={props.style} className={classes.inSectionContainer}>{props.children}</div>
    );
}

export function BehindSectionExtension(props: SectionExtensionProps) {
    const classes = useStyles();
    return (
        <div style={props.style} className={classes.behindSectionContainer}>{props.children}</div>
    );
}

interface EditableSectionProps extends PropsWithChildren<any> {
    section: ISection;
    editMode: boolean;
    userEditMode: boolean;

    onSaveSection(section: ISection): void;

    onRemoveSection(section: ISection): void;
}

export default function EditableSection(props: EditableSectionProps) {
    const {section, editMode, userEditMode, onSaveSection, onRemoveSection} = props;

    const [content, setContent] = useState(section.content);
    const [enable, setEnable] = useState(false);
    const [hovered, setHovered] = useState(false);

    const classes = useStyles();

    const handleSaveSection = () => {
        onSaveSection({content: content, type: section.type, position: section.position});
        setEnable(false);
    }

    const handleRemoveSection = () => {
        onRemoveSection(section);
    }

    const handleMouseEnter = () => {
        setHovered(true);
    }

    const handleMouseLeave = () => {
        setHovered(false);
    }

    const handleContentChange = (content: string) => {
        setContent(content);
    }

    const handleToggleEnable = () => {
        setEnable(!enable);
    }

    const beforeChildren = () => {
        let beforeChildren: any[] = [];
        React.Children.forEach(props.children, function (child) {
            if (child.type.name === BeforeSectionExtension.name) {
                beforeChildren.push(child);
            }
        });
        return beforeChildren;
    }

    const inChildren = () => {
        let inChildren: any[] = [];
        React.Children.forEach(props.children, function (child) {
            if (child.type.name === InSectionExtension.name) {
                inChildren.push(child);
            }
        });
        return inChildren;
    }

    const behindChildren = () => {
        let behindChildren: any[] = [];
        React.Children.forEach(props.children, function (child) {
            if (child.type.name === BehindSectionExtension.name) {
                behindChildren.push(child);
            }
        });
        return behindChildren;
    }

    return (
        <section id={section.type}>
            {beforeChildren()}
            <div className={classes.sectionContainer}>
                <div className={`${classes.sectionEditorContainer}${(editMode || userEditMode) ? " " +
                    (enable ? classes.sectionContainerEnabled : classes.sectionContainerEditable) : ""}`}
                     onMouseEnter={handleMouseEnter}
                     onMouseLeave={handleMouseLeave}>
                    {(editMode || userEditMode) && (
                        <EditableSectionHeader position={section.position}
                                               label={section.type}
                                               hovered={hovered}
                                               editMode={enable}
                                               userEditMode={userEditMode}
                                               onToggleEditMode={handleToggleEnable}
                                               onSaveSection={handleSaveSection}
                                               onRemoveSection={handleRemoveSection}/>
                    )}
                    <Editor editMode={enable}
                            content={content}
                            onContentChange={handleContentChange}
                    />
                    {inChildren()}
                </div>
            </div>
            {behindChildren()}
        </section>
    );
}
