import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {addDays, endOfWeek, format, getISOWeek, isBefore, isEqual, startOfWeek} from "date-fns";
import deLocale from "date-fns/locale/de";
import DayOfWeek from "./DayOfWeek";
import WeekSelector from "./WeekSelector";

const useStyles = makeStyles(() => createStyles({
    weekCarouselContainer: {
        display: "flex",
        flexDirection: "column",
        minWidth: "320px"
    },
    weekCarouselSelectionContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    weekCarouselSeparator: {
        marginTop: "20px",
        marginBottom: "20px",
        borderColor: "#F6F7F9",
        borderWidth: "1px",
        borderStyle: "solid"
    },
    weekCarouselMonthAndYear: {
        textAlign: "center",
        textTransform: "uppercase",
        fontWeight: 500
    },
    weekCarouselCalendarWeek: {
        textAlign: "center",
        fontSize: "10px",
        lineHeight: "10px",
        fontWeight: 400
    }
}));

export type WeekDay = {
    date: Date,
    count: number
}

const getDatesBetween = (startDate: Date, endDate: Date) => {
    const dates = [];

    let currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

    while (currentDate <= endDate) {
        dates.push(currentDate);

        currentDate = addDays(currentDate, 1);
    }

    return dates;
}

interface WeekCarouselProps extends PropsWithChildren<any> {
    selectedWeek: WeekDay[];

    onSelectedDayOfWeek(dayOfWeek: Date): void;

    onSelectedWeek(week: Date[]): Promise<void>;
}

export default function WeekCarousel(props: WeekCarouselProps) {

    const {selectedWeek, onSelectedDayOfWeek, onSelectedWeek} = props;

    const classes = useStyles();
    const now: Date = useMemo<Date>(() => {
        return new Date(new Date().setHours(0, 0, 0, 0));
    }, []);

    const [selectedDate, setSelectedDate] = useState<Date>(now);
    const [selectedWeekHasDaysInPast, setSelectedWeekHasDaysInPast] = useState<boolean>(true);

    const handleWeekUpdate = useCallback((selectedDate: Date): void => {
        const startOfWeekValue = startOfWeek(selectedDate, {weekStartsOn: 1});
        const endOfWeekValue = endOfWeek(selectedDate, {weekStartsOn: 1});
        const weekValue = getDatesBetween(startOfWeekValue, endOfWeekValue);

        onSelectedWeek(weekValue).catch((error) => {
            console.error("unexpected error: " + error.message);
        });

        onSelectedDayOfWeek(selectedDate);

        setSelectedDate(selectedDate);
    }, [onSelectedDayOfWeek, onSelectedWeek]);

    const handleDayInPast = useCallback((dateOfWeek: Date): boolean => {
        return isEqual(dateOfWeek, now) || isBefore(dateOfWeek, now)
    }, [now]);

    useEffect(() => {
        handleWeekUpdate(now);
    }, [handleWeekUpdate, now]);

    useEffect(() => {
        setSelectedWeekHasDaysInPast(selectedWeek
            .filter((dayOfWeek: WeekDay) => handleDayInPast(dayOfWeek.date)).length !== 0);
    }, [selectedWeek, handleDayInPast]);

    const handlePrevWeek = () => {
        if (!selectedWeekHasDaysInPast) {
            let currentSelectedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - 7);
            while (isBefore(currentSelectedDate, now)) {
                console.info("current day is in past, next", currentSelectedDate);
                currentSelectedDate.setDate(currentSelectedDate.getDate() + 1);
            }
            handleWeekUpdate(currentSelectedDate);
        } else {
            console.log("dont go back in past");
        }
    }

    const handleNextWeek = () => {
        handleWeekUpdate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 7));
    }

    const handleDayIsSelected = (dayOfWeek: Date): boolean => {
        return isEqual(dayOfWeek, selectedDate);
    }

    const handleDaySelected = (selectedIndex: number) => {
        const selectedDate = selectedWeek[selectedIndex];
        if (handleDayInPast(selectedDate.date)) {
            handleDaySelected(selectedIndex + 1)
        }
        setSelectedDate(selectedDate.date);
        onSelectedDayOfWeek(selectedDate.date);
    }

    return (
        <div className={classes.weekCarouselContainer}>
            <div className={classes.weekCarouselMonthAndYear}>
                {format(selectedDate, "MMMM yyyy", {locale: deLocale, weekStartsOn: 1})}
            </div>
            <div className={classes.weekCarouselCalendarWeek}>KW {getISOWeek(selectedDate)}</div>
            <div className={classes.weekCarouselSeparator}/>
            <div className={classes.weekCarouselSelectionContainer}>
                <WeekSelector type="left"
                              disabled={selectedWeekHasDaysInPast}
                              iconStyle={{display: `${selectedWeekHasDaysInPast ? "none" : "inline-block"}`}}
                              onClick={handlePrevWeek}/>
                {selectedWeek.map((dayOfWeek: WeekDay, index: number) => (
                    <DayOfWeek key={index}
                               dayOfWeek={dayOfWeek.date}
                               indexOfWeek={index}
                               currentSlotCount={dayOfWeek.count}
                               onDaySelected={handleDaySelected}
                               onDayIsSelected={handleDayIsSelected}
                    />
                ))}
                <WeekSelector type="right" onClick={handleNextWeek}/>
            </div>
            <div className={classes.weekCarouselSeparator}/>
        </div>
    );
}
