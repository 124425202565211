import React, {PropsWithChildren, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import FilterDropdown, {BlueFilterDropdown} from "../../../components/FilterDropdown";
import IBoat, {Location, locationToText} from "../../../model/boat/IBoat";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    bookingFilterContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#ffffff",
        margin: "0 auto",
        padding: "20px 0",
        width: "calc(415px + 415px + 20px)",
        [theme.breakpoints.down(950)]: {
            width: "calc(415px - 40px)",
        },
        [theme.breakpoints.down(415)]: {
            margin: "0",
            width: "100%"
        }
    },
    bookingFilterHeadline: {
        color: "#023553",
        fontWeight: 500,
        lineHeight: "30px",
        margin: "0 20px 20px",
        textTransform: "uppercase",
        [theme.breakpoints.down(950)]: {
            marginRight: "auto"
        },
    },
    bookingFilterNotifyMessage: {
        color: "#023553",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        margin: "0 30px",
    },
    bookingFilterDropdownContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        margin: "0 20px",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
        },
    },
    bookingFilterDropdownInput: {
        marginRight: "12px",
        width: "33%",
        [theme.breakpoints.down(950)]: {
            width: "100%",
            marginRight: "0",
            marginBottom: "10px"
        }
    }
}));

export type BookingBoatFilter = {
    boatLocation?: Location;
    boatType?: string;
    selectedBoatNumber?: string;
};

interface BookingFilterProps extends PropsWithChildren<any> {
    boats: IBoat[];
    preSelectedBoatNumber?: string;

    boatTypes: string[];
    notifyMessage?: string;

    onUpdateFilter(filter: BookingBoatFilter): void;
}

export default function BookingFilter(props: BookingFilterProps) {
    const [filteredBoatType, setFilteredBoatType] = useState<string>();
    const [filteredBoatLocation, setFilteredBoatLocation] = useState<Location>();
    const [selectedBoatNumber, setSelectedBoatNumber] = useState<string | undefined>(props.preSelectedBoatNumber);
    const [filter, setFilter] = useState<BookingBoatFilter>({});

    const {boats, boatTypes, notifyMessage} = props;

    const classes = useStyles();

    useEffect(() => {
        setFilter({
            boatType: filteredBoatType,
            boatLocation: filteredBoatLocation,
            selectedBoatNumber: selectedBoatNumber
        });
    }, [
        filteredBoatType,
        filteredBoatLocation,
        selectedBoatNumber
    ]);

    useEffect(() => {
        props.onUpdateFilter(filter);
    }, [props, filter]);

    useEffect(() => {
        setSelectedBoatNumber(props.preSelectedBoatNumber);
    }, [props.preSelectedBoatNumber])

    const handleFilterBoat = (boat: IBoat) => {
        return (filter?.boatType === undefined || boat.type === filter.boatType)
            && (filter?.boatLocation === undefined || boat.location === filter.boatLocation);
    }

    const filteredBoats = boats.filter(boat => handleFilterBoat(boat));

    return (
        <div className={classes.bookingFilterContainer}>
            <div className={classes.bookingFilterHeadline}>Boot auswählen</div>
            {notifyMessage && (<div className={classes.bookingFilterNotifyMessage}>{notifyMessage}</div>)}
            <div className={classes.bookingFilterDropdownContainer}>
                <BlueFilterDropdown id={"boat-name-filter"}
                                    className={classes.bookingFilterDropdownInput}
                                    label={"Bootsname"}
                                    value={!!selectedBoatNumber ? selectedBoatNumber : ""}
                                    onChange={(event: any) => {
                                        setSelectedBoatNumber(event.target.value === "" ?
                                            undefined : event.target.value);
                                    }}>
                    <MenuItem value={""}>Keinen Bootsnamen</MenuItem>
                    {filteredBoats.map((boat: IBoat, index: number) => (
                        <MenuItem key={index} value={boat.number}>{boat.name}</MenuItem>
                    ))}
                </BlueFilterDropdown>
                <FilterDropdown id={"boat-location-filter"}
                                className={classes.bookingFilterDropdownInput}
                                label={"Liegeplatz"}
                                defaultValue={""}
                                onChange={(event: any) => {
                                    if (event.target.value === "") {
                                        setFilteredBoatLocation(undefined);
                                    } else {
                                        setFilteredBoatLocation(event.target.value);
                                        setSelectedBoatNumber(undefined);
                                    }
                                }}>
                    <MenuItem value={""}>Kein Liegeplatz</MenuItem>
                    <MenuItem value={Location.BORNHORST}>{locationToText(Location.BORNHORST)}</MenuItem>
                    {/*<MenuItem value={Location.BUENTING}>{locationToText(Location.BUENTING)}</MenuItem>*/}
                    <MenuItem value={Location.EYHAUSEN}>{locationToText(Location.EYHAUSEN)}</MenuItem>
                    <MenuItem value={Location.OELTJEN}>{locationToText(Location.OELTJEN)}</MenuItem>
                </FilterDropdown>
                <FilterDropdown id={"boat-type-filter"}
                                style={{marginRight: "0"}}
                                className={classes.bookingFilterDropdownInput}
                                label={"Bootsklasse"}
                                defaultValue={""}
                                onChange={(event: any) => {
                                    if (event.target.value === "") {
                                        setFilteredBoatType(undefined);
                                    } else {
                                        setFilteredBoatType(event.target.value);
                                        setSelectedBoatNumber(undefined);
                                    }
                                }}>
                    <MenuItem value={""}>Keine Bootsklasse</MenuItem>
                    {boatTypes.map((boatType: string, index: number) => (
                        <MenuItem key={index} value={boatType}>{boatType}</MenuItem>
                    ))}
                </FilterDropdown>
            </div>
        </div>
    );

}
