export enum UserRole {
    MEMBER = "MEMBER",
    EDIT_BOAT = "EDIT_BOAT",
    EDIT_COURSE = "EDIT_COURSE",
    EDIT_CONTENT = "EDIT_CONTENT",
    BOOKING = "BOOKING",
    MULTI_BOOKING = "MULTI_BOOKING"
}

export function roleToText(role: UserRole) {
    switch (role) {
        case UserRole.MEMBER:
            return "Mitgliederbereich sehen";
        case UserRole.EDIT_BOAT:
            return "Boote bearbeiten";
        case UserRole.EDIT_COURSE:
            return "Kurse bearbeiten";
        case UserRole.EDIT_CONTENT:
            return "Inhalten bearbeiten";
        case UserRole.BOOKING:
            return "Buchen"
        case UserRole.MULTI_BOOKING:
            return "Mehrfaches buchen"
        default:
            return "";
    }
}

export enum UserGroup {
    ADMINISTRATOR = "ADMINISTRATOR",
    BOARD_MEMBER = "BOARD_MEMBER",
    BOATSWAIN = "BOATSWAIN",
    CLUB_MEMBER = "CLUB_MEMBER",
    INSTRUCTOR = "INSTRUCTOR",
    ORGANIZER = "ORGANIZER",
    COURSE_ORGANIZER = "COURSE_ORGANIZER"
}

export function groupToText(group: UserGroup) {
    switch (group) {
        case UserGroup.ADMINISTRATOR:
            return "Administrator";
        case UserGroup.BOARD_MEMBER:
            return "Vorstandsmitglied";
        case UserGroup.BOATSWAIN:
            return "Bootsmann / Bootsfrau";
        case UserGroup.CLUB_MEMBER:
            return "Mitglied";
        case UserGroup.INSTRUCTOR:
            return "Ausbilder:in";
        case UserGroup.ORGANIZER:
            return "Organisator:in";
        case UserGroup.COURSE_ORGANIZER:
            return "Kursorganisator:in"
        default:
            return "";
    }
}

export default interface IUser {
    userId: string;
    username: string;
    mailAddress: string;
    firstName?: string;
    lastName?: string;
    roles: UserRole[];
    groups: UserGroup[];
    isInUserRole(role: UserRole): boolean;
    isInUserGroup(group: UserGroup): boolean;
    authenticated: boolean;
    expired: boolean;
}
