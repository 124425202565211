import React, {PropsWithChildren, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import FilterDropdown from "../../../components/FilterDropdown";
import FilterSearchInput from "../../../components/FilterSearchInput";
import {Location, locationToText} from "../../../model/boat/IBoat";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    boatsFilterContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "#ffffff",
        margin: "auto auto 40px",
        padding: "20px 0",
        width: "calc(415px + 415px + 20px)",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
            margin: "20px auto",
            padding: "20px",
            width: "calc(415px - 40px)",
        },
        [theme.breakpoints.down(415)]: {
            margin: "0 0 20px 0",
            width: "calc(100% - 40px)"
        }
    },
    boatsFilterHeadline: {
        color: "#023553",
        fontWeight: 500,
        lineHeight: "56px",
        marginRight: "12px",
        [theme.breakpoints.down(950)]: {
            marginRight: "auto"
        }
    },
    boatsFilterDropdownInput: {
        marginRight: "12px",
        [theme.breakpoints.down(950)]: {
            marginRight: "0",
            marginBottom: "10px"
        }
    },
    boatsFilterSearchInput: {
        width: "250px",
        [theme.breakpoints.down(950)]: {
            width: "100%"
        }
    }
}));

export type BoatFilter = {
    boatType?: string;
    boatLocation?: Location;
    boatName?: string;
};

interface BoatsFilterProps extends PropsWithChildren<any> {
    boatTypes: string[];

    onUpdateFilter(filter: BoatFilter): void;
}

export default function BoatsFilter(props: BoatsFilterProps) {
    const [filteredBoatType, setFilteredBoatType] = useState<string>();
    const [filteredBoatLocation, setFilteredBoatLocation] = useState<Location>();
    const [searchedBoatName, setSearchedBoatName] = useState<string>();
    const [filter, setFilter] = useState<BoatFilter>({});

    const {boatTypes} = props;

    const classes = useStyles();

    useEffect(() => {
        setFilter({
            boatType: filteredBoatType,
            boatLocation: filteredBoatLocation,
            boatName: searchedBoatName
        })
    }, [
        filteredBoatType,
        filteredBoatLocation,
        searchedBoatName
    ]);

    useEffect(() => {
        props.onUpdateFilter(filter);
    }, [props, filter]);

    return (
        <div className={classes.boatsFilterContainer}>
            <div className={classes.boatsFilterHeadline}>FILTER:</div>
            <FilterDropdown id={"boat-type-filter"}
                            className={classes.boatsFilterDropdownInput}
                            label={"Bootsklasse"}
                            defaultValue={""}
                            onChange={(event: any) => {
                                setFilteredBoatType(event.target.value === "" ?
                                    undefined : event.target.value);
                            }}>
                <MenuItem value={""}>Keinen Typ</MenuItem>
                {boatTypes.map((boatType: string, index: number) => (
                    <MenuItem key={index} value={boatType}>{boatType}</MenuItem>
                ))}
            </FilterDropdown>
            <FilterDropdown id={"boat-location-filter"}
                            className={classes.boatsFilterDropdownInput}
                            label={"Liegeplatz"}
                            defaultValue={""}
                            onChange={(event: any) => {
                                setFilteredBoatLocation(event.target.value === "" ?
                                    undefined : event.target.value);
                            }}>
                <MenuItem value={""}>Kein Liegeplatz</MenuItem>
                <MenuItem value={Location.BORNHORST}>{locationToText(Location.BORNHORST)}</MenuItem>
                {/*<MenuItem value={Location.BUENTING}>{locationToText(Location.BUENTING)}</MenuItem>*/}
                <MenuItem value={Location.EYHAUSEN}>{locationToText(Location.EYHAUSEN)}</MenuItem>
                <MenuItem value={Location.OELTJEN}>{locationToText(Location.OELTJEN)}</MenuItem>
            </FilterDropdown>
            <FilterSearchInput id={"boat-search-filter"}
                               className={classes.boatsFilterSearchInput}
                               label={"Bootsname"}
                               defaultValue={""}
                               onChange={(event: any) => {
                                   setSearchedBoatName(event.target.value === "" ?
                                       undefined : event.target.value);
                               }}/>
        </div>
    );

}
