import React, {PropsWithChildren, useEffect, useRef} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import ContentButton from "../ContentButton";
import {MessageData} from "./MessageList";
import {messageTypeToText} from "../../model/news/IMessage";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    messageBackgroundContainer: {
        backgroundColor: "#ffffff",
        margin: "0 20px 20px 20px",
        minWidth: "calc(100% - 40px)",
        [theme.breakpoints.down(415)]: {
            margin: "0 0 20px",
            width: "100%"
        }
    },
    messageContainer: {
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    messageContentContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    messageMoreButton: {
        width: "120px",
        fontSize: "13px",
        lineHeight: "19px",
        margin: "0 auto 0 auto",
        borderRadius: "4px"
    },
    messageContent: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#363E4C",
        margin: "0 20px 15px"
    },
    messageTitleContainer: {
        display: "flex",
        flexDirection: "row",
        lineHeight: "19px",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column"
        }
    },
    messageTitle: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#02B8B7",
        padding: "15px 20px",
        flexGrow: 100,
        [theme.breakpoints.down(950)]: {
            padding: "15px 20px 0 20px"
        }
    },
    messageTitleDate: {
        color: "#363E4C",
        textAlign: "right",
        fontSize: "13px",
        padding: "15px 20px",
        [theme.breakpoints.down(950)]: {
            padding: "0 20px 15px 20px",
            textAlign: "left"
        }
    },
    messageTitleUnderline: {
        backgroundColor: "#363E4C",
        height: "1px",
        width: "calc(100% - 40px)",
        margin: "0 20px",
        position: "absolute",
        top: "34px",
        [theme.breakpoints.down(950)]: {
            top: "54px"
        }
    },
}));

interface MessageProps extends PropsWithChildren<any> {
    message: MessageData;
    hero: boolean;
    expanded: boolean;
    clamped: boolean;
    showType?: boolean;

    onMakeAsClamped(number: number): void;

    onClickExpand(id: string, expanded: boolean): void;
}

export default function Message(props: MessageProps) {
    const {message, hero, expanded, clamped, showType, onMakeAsClamped, onClickExpand} = props;

    const ref = useRef<HTMLDivElement>(null);

    const classes = useStyles();

    const expand = (element: HTMLDivElement) => {
        element.style.textOverflow = ""
        element.style.overflow = ""
        element.style.display = ""
        element.style.webkitLineClamp = ""
        element.style.webkitBoxOrient = ""
    }

    const collapse = (element: HTMLDivElement) => {
        element.style.textOverflow = "ellipsis"
        element.style.overflow = "hidden"
        element.style.display = "-webkit-box"
        element.style.webkitLineClamp = "3"
        element.style.webkitBoxOrient = "vertical"
    }

    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClickExpand(event.currentTarget.id, !expanded);
    }

    // handle content div has to collapse
    useEffect(() => {
        if (ref.current) {
            if (ref.current.clientHeight > (24 * 3)) {
                onMakeAsClamped(message.number);
            }
        }
    }, [ref, message, onMakeAsClamped]);

    // handle expand content or collapse them
    useEffect(() => {
        if (ref.current) {
            if (expanded) {
                expand(ref.current);
            } else {
                collapse(ref.current);
            }
        }
    }, [ref, expanded]);

    const handleDateToLocaleString = (date: string | Date | null) => {
        if (typeof date === "string") {
            const dateTimestamp = Date.parse(date);
            date = new Date(dateTimestamp);
        }
        return !!date ?
            date.toLocaleDateString('de-DE', {day: "2-digit", month: "2-digit", year: "numeric"}) : "";
    }

    const convertContent = (content: string) => {
        console.log(content);
        return content.replaceAll(/\r\n|\r|\n/g, '<br>');
    }

    return (
        <div className={classes.messageBackgroundContainer}>
            <div className={classes.messageContainer}>
                <div className={classes.messageContentContainer}>
                    <div className={classes.messageTitleContainer}
                         style={hero ? {
                             backgroundColor: "rgb(244,216,75)",
                             color: "#363E4C",
                             marginBottom: "15px"
                         } : {}}>
                        <div className={classes.messageTitle}
                             style={hero ? {color: "#363E4C", textTransform: "uppercase"} : {}}>{message.title}</div>
                        <div className={classes.messageTitleDate}>
                            {showType ? messageTypeToText(message.type) + " | " : ""}{handleDateToLocaleString(message.date)} | {message.author}
                        </div>
                        <div className={classes.messageTitleUnderline} style={hero ? {display: "none"} : {}}/>
                    </div>
                    <div ref={ref}
                         id={"message-content-" + message.number}
                         className={classes.messageContent}
                         dangerouslySetInnerHTML={{__html: !expanded ? message.content : convertContent(message.content)}}/>
                    {clamped && (
                        <ContentButton id={"" + message.number}
                                       className={classes.messageMoreButton}
                                       style={{marginBottom: "15px"}}
                                       onClick={handleOnClick}>{!expanded ? "Mehr" : "Weniger"}</ContentButton>
                    )}
                </div>
            </div>
        </div>
    );
}
